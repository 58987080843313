import styled from 'styled-components'

export default styled.thead`
  tr {
    background-color: #121212;
    th {
      padding: 10px 10px 10px 12px;
    }
  }
`
