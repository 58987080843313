import React, { memo } from 'react'
import PropTypes from 'prop-types'

import TableHeaderStyled from './styled'

export const TableHeader = props => {
  return <TableHeaderStyled className={props.className}>{props.children}</TableHeaderStyled>
}

TableHeader.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.element, PropTypes.array]),
  className: PropTypes.string,
}

export default memo(TableHeader)
